import req from './http'
//定义接口
class API {
  // https://site.musicwow.com/api/v1/bg/list
  list =params=>req('post','api/v1/bg/list',params)
  // 登录
  getLogin =params=>req('post','helper/login',params)
  // 老板角色首页
  getBossPage =params=>req('get','helper/statistics/bossPage',params)
  // 门店列表
  getShopList =params=>req('get','helper/statistics/shopList',params)
  // 今日资金收入
  getCapital =params=>req('get','helper/statistics/getCapital',params)
  // 金额概况
  getAmountOfStatus =params=>req('get','helper/statistics/amountOfStatus',params)
  // 7天走势图
  getTrend =params=>req('get','helper/statistics/trend',params)
  // 上座率
  getOnTheChance =params=>req('get','helper/statistics/onTheChance',params)
  // 当日营收状况
  getRevenue =params=>req('get','helper/statistics/getRevenue',params)
  // 7天营收走势图
  getRevenueForSevenDays =params=>req('get','helper/statistics/revenueForSevenDays',params)
  //资金历史收入
  getHistoricalIncome =params=>req('get','helper/statistics/historicalIncome',params)
  //历史营收
  getRevenueList =params=>req('get','helper/statistics/revenueList',params)


}

export default new API()
