import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from './views/login/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import( './views/home/index.vue')
  },
  {
    path: '/Store',
    name: 'Store',
    component: () => import( './views/store/index.vue')
  },
  {
    path: '/Capital',
    name: 'Capital',
    component: () => import( './views/capital/index.vue')
  },
  {
    path: '/Business',
    name: 'Business',
    component: () => import('./views/business/index.vue')
  },
  {
    path: '/Attendance',
    name: 'Attendance',
    component: () => import('./views/attendance/index.vue')
  },
  {
    path: '/History',
    name: 'History',
    component: () => import('./views/capital/history/list.vue')
  },
  {
    path: '/Revenue',
    name: 'Revenue',
    component: () => import('./views/capital/history/revenue.vue')
  },
  {
    path: '/Income',
    name: 'Income',
    component: () => import('./views/capital/history/income.vue')
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
