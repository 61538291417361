<template>
  <div class="login">
    <div class="title">
      <div class="title-item">
        <img src="~@/assets/img/login/log.png" />
        <div class="item-text">霖感门店管家</div>
      </div>
    </div>
    <div class="centent">
      <div class="phone">
        <div class="phone-item">
          <img class="item-ico" src="~@/assets/img/login/phone.png" />
          <p class="item-text">账户</p>
        </div>
        <div class="phone-input">
          <input
            v-model="phone"
            maxlength="50"
            class="input-text"
            placeholder="请输入手机号码"
          />
        </div>
      </div>
      <div class="phone">
        <div class="password-item">
          <img class="item-ico" src="~@/assets/img/login/password.png" />
          <p class="item-text">密码</p>
        </div>
        <div class="phone-input">
          <input
            type="password"
            v-model="password"
            maxlength="50"
            class="input-text"
            placeholder="请输入你的密码"
          />
        </div>
      </div>
    </div>
    <div class="but">
      <div class="but-item" @click="handleLoginData">立即登录</div>
    </div>
    <Loading v-show="showAdd"></Loading>
  </div>
</template>

<script>
import { Toast } from "vant";
import Loading from '@/components/lod'
export default {
  name: "login",
  components:{
    Loading
  },
  data() {
    return {
      showAdd: false,
      phone: "",
      password: ""
    };
  },
  mounted() {
    // console.log(process.env)
  },
  methods: {
    // 登录
    handleLoginData() {
      let _this = this;
      if (!_this.phone) {
       Toast("请输入账号");
        return;
      }
      if (!_this.password) {
        Toast("请输入密码");
        return;
      }
      _this.showAdd = true
      _this.$API.getLogin({
          userName: _this.phone,
          password: _this.password
        })
        .then(function(res) {
          if (res.code == 200) {
            _this.showAdd = false
            localStorage.token = res.data.token;
            localStorage.nickName = res.data.userInfo.nickName;
            localStorage.roleType = res.data.userInfo.roleType;
            let roleType = res.data.userInfo.roleType;
            localStorage.userId = res.data.userInfo.id;
            localStorage.shopId = res.data.userInfo.shopId;
            localStorage.shopName = res.data.userInfo.shopName;
            if (roleType == 1) {
              _this.$router.push({ name: "Home" });
            } else {
              _this.$router.push({ name: "Capital" });
            }
          } else {
            _this.showAdd = false
            Toast(res.msg);
          }
        }).catch((res)=>{
          _this.showAdd = false

        });
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  .title {
    width: 100%;
    height: 501px;
    background: url("~@/assets/img/login/log-bg.png");
    background-size: 160% 160%;
    background-position-x: 65%;
    background-position-y: 100%;
    overflow: hidden;
    .title-item {
      width: 508px;
      margin: auto;
      margin-top: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border-radius: 17px;
      box-shadow: 0px 30px 49px 0px rgba(8, 175, 242, 0.1);
      padding: 20px 0;

      img {
        width: 37.3px;
        height: 44.3px;
      }
      .item-text {
        margin-left: 22px;
        font-size: 40px;
        color: #222222;
        font-weight: bold;
      }
    }
  }
  .centent {
    padding: 10px 60px;
    .phone {
      padding: 20px 0;
      .phone-item {
        display: flex;
        align-items: center;
        .item-ico {
          width: 17px;
          height: 25px;
        }
        .item-text {
          color: #444444;
          font-size: 30px;
          margin-left: 20px;
        }
      }
      .password-item {
        display: flex;
        align-items: center;
        .item-ico {
          width: 19px;
          height: 25px;
        }
        .item-text {
          color: #444444;
          font-size: 30px;
          margin-left: 20px;
        }
      }
      .phone-input {
        padding: 20px 38px;
        border-bottom: 1px solid #d8d8d8;
        .input-text {
          width: 100%;
          height: 60px;
          // padding: 20px;
          font-size: 26px;
          outline: none;
          background: none;
          border: none;
        }
      }
    }
  }
  .but {
    position: relative;
    width: 100%;
    height: 234px;
    background: url("~@/assets/img/login/add-bg.png");
    background-size: 100% 100%;
    .but-item {
      color: #fff;
      font-size: 36px;
      position: absolute;
      left: 50%;
      top: 42%;
      transform: translate(-50%, -50%);
    }
  }
}
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #d8d8d8;
  font-size: 26px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #d8d8d8;
  font-size: 26px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #d8d8d8;
  font-size: 26px;
}
</style>
