import axios from 'axios'
import qs from 'qs'
localStorage.url = process.env.VUE_APP_URL
// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
//创建axios的一个实例 baseURL: 'https://weapp.musicwow.com:9443/',
// process.env.NODE_ENV === 'development'?'http://helper.lingane-sport.com':'./', 
// let url = 'http://helper.lingane-sport.com'
var instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, //process.env.VUE_APP_URL,  47.111.85.71
    // baseURL: process.env.VUE_APP_URL+ '/hp-api/', //process.env.VUE_APP_URL,  47.111.85.71
    // baseURL: url,
    timeout: 60000,
    headers : {
        'Content-Type':'application/json;charset=utf-8'
    },
    // headers : {
    //   'Content-Type':'application/x-www-form-urlencoded'
    // },
})
// let config = {
//   headers : {
//     'Content-Type':'application/x-www-form-urlencoded'
//   },
// };

//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  let getToken = localStorage.token
  if (getToken && !isToken) {
    config.headers['token'] = getToken // 让每个请求携带自定义token 请根据实际情况自行修改
  }
return config;
}, function (error) {
    console.log(error)
    // 对请求错误做些什么

    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {
    if(response.data.code == 403) {
        location.href = '/';
    }
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

        // return instance.post(url, qs.stringify(data) )

export default function (method, url, data = null) {
    method = method.toLowerCase();
    if (method == 'post') {
        return instance.post(url,  data )
    } else if (method == 'get') {
        return instance.get(url,{ params: data })
    } else if (method == 'delete') {
        return instance.delete(url, { params: data })
    }else if(method == 'put'){
        return instance.put(url,data)
    }
    // else{
    //     console.error('未知的method'+method)
    //     return false
    // }
}
