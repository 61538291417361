<template>
<div class="lod">
    <div class="lod-item">
      <van-loading type="spinner"/>
    </div>
  </div>
</template>
<script>
import { Loading } from 'vant';
export default {
  name: 'lod',
  components:{
    Loading
  },
}
</script>

<style lang="scss" scoped>
.lod{
  position: fixed;
  width:100%;
  height:100%;
  background:rgba(0,0,0,0.5);
  z-index:888;
  top:0;
  left:0;
  text-align: center;
  line-height:60px;
  border-radius: 5px;
}
.lod-item{
  position: fixed;
  width:100px;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 999;
  font-size: 40px;
 

}
/deep/ .van-loading__spinner{
  width: 60px;
  height: 60px;
}
</style>