
import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import '@/assets/css/init.css'
import router from './router'
import store from './store'
import API from './api/api'
import 'lib-flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import '@/assets/css/base.scss'
// import API from './api/api'
import { parseTime, } from "@/utils/check";
import { Button, Toast, DatetimePicker,Field,Popup  ,Picker,List, Loading   } from 'vant';
// import 'vant/lib/vant-css/index.css';
Vue.use(Button);
Vue.use(Toast);
Vue.use(DatetimePicker);
Vue.use(Field);
Vue.use(Popup );
Vue.use(Picker);
Vue.use( Loading );
Vue.use(List);
Vue.use(ElementUI);

Vue.prototype.$echarts = echarts;
Vue.prototype.parseTime = parseTime
Vue.prototype.$ajax= axios
Vue.prototype.$API = API

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
